<template>
    <div>
        <el-col :span="24" class="topTools">
            <el-form :inline="true">
                <el-form-item label="关键字:">
                    <el-input
                        placeholder="输入关键字筛选"
                        clearable
                        @clear='clearContent'
                        v-model="filterText">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <tool-bar class="editSom" :buttonList="buttonList" @callFunction="callFunction"></tool-bar>
                </el-form-item>
            </el-form>
        </el-col>

        <!--列表-->
        <el-table
        class="bodyTools"
        :data="treeData"
        highlight-current-row
        v-loading="listLoading"
        @selection-change="selsChange"
        @current-change="selectCurrentRow"
        row-key="Id"
        border
        lazy
        max-height="680"
        :load="load"
        :tree-props="{children: 'children', hasChildren: 'HasChildren'}"
        style="width: 100%;"
        ref="multipleTable"
        >
            <!-- <el-table-column type="selection" width="50" align="center"></el-table-column> -->
            <!-- <el-table-column type="index" label="序号" width="80" align="center"></el-table-column> -->
            <el-table-column label="菜单/按钮" min-width="110">
                <template slot-scope="scope">
                <i class="fa" :class="scope.row.Icon"></i>

                {{scope.row.Name}}
                </template>
            </el-table-column>
            <el-table-column prop="RoutingPath" label="路由地址" min-width="100"></el-table-column>
            <el-table-column prop="LinkUrl" label="API接口" min-width="100"></el-table-column>
            <el-table-column prop="IsButton" label="是否按钮" min-width="100" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.IsButton  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.IsButton ? "否":"是"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="FuncEvent" label="按钮事件" min-width="100"></el-table-column>
            <el-table-column prop="CreateTime" label="创建时间" :formatter="formatCreateTime" min-width="120" align="center"></el-table-column>
            <el-table-column prop="IsHide" label="是否隐藏" min-width="100" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.IsHide  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.IsHide ? "否":"是"}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsEnable" label="启用标识" min-width="100" align="center">
                <template slot-scope="scope">
                <el-tag
                    :type="!scope.row.IsEnable  ? 'danger' : 'success'"
                    disable-transitions
                >{{!scope.row.IsEnable ? "禁用":"启用"}}</el-tag>
                </template>
            </el-table-column>
        </el-table>

        <!--新增界面-->
        <el-dialog
        :title="'新增'"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm" style="max-width: 700px;">
                <el-form-item label="菜单名称：" prop="Name">
                    <el-input v-model="addForm.Name" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>

                <el-form-item label="路由地址：" prop="Code">
                    <el-tooltip placement="top">
                        <div slot="content">
                            如果是一级目录，请填‘-’字符，子集目录请输入/xx/xx即可
                            <br />如果是按钮，请输入空格即可
                            <br />如果是外链，请带上协议，比如 https://www.------.com
                            <br />
                        </div>
                        <el-input v-model="addForm.Code" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addForm.Description" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="启用标识：" prop="Enabled">
                    <el-select lect v-model="addForm.Enabled" placeholder="请选择状态">
                        <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.Name"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：" prop="OrderSort">
                    <el-input v-model="addForm.OrderSort" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsButton" label="是否按钮：" width sortable>
                    <el-switch v-model="addForm.IsButton"></el-switch>
                </el-form-item>
                <el-form-item label="按钮事件：" prop="Func">
                    <el-input v-model="addForm.Func" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Icon：">
                    <el-input v-model="addForm.Icon" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsHide" label="隐藏菜单：" width sortable>
                    <el-switch v-model="addForm.IsHide"></el-switch>
                </el-form-item>
                <el-form-item prop="PidArr" label="父级菜单：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.PidArr"
                        :options="options"
                        :props="defaultProps"
                        :key="isResouceShow"
                        filterable
                        change-on-select
                    ></el-cascader>
                </el-form-item>

                <el-form-item prop="PidArr" label="API接口：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.apiMid"
                        :props="defaultProps1"
                        filterable
                        change-on-select
                        :placeholder="cascTips"
                    >
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisible = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit('addForm')" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>

        <!--新增界面-->
        <el-dialog
        :title="'编辑'"
        :visible.sync="addFormVisiblec"
        v-model="addFormVisiblec"
        :close-on-click-modal="false"
        >
            <el-form :model="addForm" label-width="100px" :rules="addFormRules" ref="addForm" style="max-width: 700px;">
                <el-form-item label="菜单名称：" prop="Name">
                    <el-input v-model="addForm.Name" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>

                <el-form-item label="路由地址：" prop="Code">
                    <el-tooltip placement="top">
                        <div slot="content">
                            如果是一级目录，请填‘-’字符，子集目录请输入/xx/xx即可
                            <br />如果是按钮，请输入空格即可
                            <br />如果是外链，请带上协议，比如 https://www.------.com
                            <br />
                        </div>
                        <el-input v-model="addForm.Code" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                    </el-tooltip>
                </el-form-item>
                <el-form-item label="描述：" prop="Description">
                    <el-input v-model="addForm.Description" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="启用标识：" prop="Enabled">
                    <el-select lect v-model="addForm.Enabled" placeholder="请选择状态">
                        <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.Name"
                        :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序：" prop="OrderSort">
                    <el-input v-model="addForm.OrderSort" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsButton" label="是否按钮：" width sortable>
                    <el-switch v-model="addForm.IsButton"></el-switch>
                </el-form-item>
                <el-form-item label="按钮事件：" prop="Func">
                    <el-input v-model="addForm.Func" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="Icon：">
                    <el-input v-model="addForm.Icon" @keyup.enter.native="enterKey('addForm')" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item prop="IsHide" label="隐藏菜单：" width sortable>
                    <el-switch v-model="addForm.IsHide"></el-switch>
                </el-form-item>
                <el-form-item prop="PidArr" label="父级菜单：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.PidArr"
                        :options="options"
                        :props="defaultProps"
                        :key="isResouceShow"
                        filterable
                        change-on-select
                    ></el-cascader>
                </el-form-item>

                <el-form-item prop="PidArr" label="API接口：" width sortable>
                    <el-cascader
                        style="width: 400px"
                        v-model="addForm.apiMid"
                        :props="defaultProps1"
                        filterable
                        change-on-select
                        :placeholder="cascTips"
                    >
                    </el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormVisiblec = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit('addForm')" :loading="addLoading">提交</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import toolBar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getMenuPermissionsTree,getApiModulesInfoTree,addMenuPermissionsInfo,updateMenuPermissionsInfo,deleteMenuPermissionsInfo,getMenuTree } from "../../api/api";
import util from "../../../util/date";
import Qs from 'qs'
let a = 1;
export default {
    components: { toolBar },
    data(){
        return{
            buttonList: [],
            treeData: [],   //列表树
            listLoading: false,

            filterText: '', //查询关键字
            isAdd: true,
            options: [],
            defaultProps: {
                children: "Children",
                label: "Name",
                value:'Id',
            },
            defaultProps1: {
                children: "children",
                label: "name",
                value:'id',
                lazy: true,
                lazyLoad (node, resolve) {
                    if(node.data){
                        let nodeId = node.data.id
                    }else{
                        let nodeId = 1
                    }
                    let params = {}
                    if(node.data){
                        if(node.data.id == "00"){
                            params.parentId = 0
                        }else{
                            params.parentId = node.data.id
                        }
                    }else{
                        params = ''
                    }
                    getApiModulesInfoTree(params)
                    .then(res => {
                        let data = res.data
                        if(data.Success){
                            const cities = data.Response.map(
                                (value, i) => ({
                                    id: value.ID,
                                    name: value.Name +' '+ value.LinkUrl,
                                    // leaf: node.level >= 2,
                                    disabled: !value.IsApi
                                })
                            );
                            if(a == 1){
                                let obj = {
                                    id: "00",
                                    name: "无需api"
                                }
                                a++
                                cities.push(obj)
                            }
                            if(nodeId == "00"){
                                let citiesc = []
                                resolve(citiesc);
                                return
                            }
                            resolve(cities);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    });
                }
            },
            modules: [], //接口api列表
            addLoading: false,
            addFormVisible: false, //新增界面是否显示
            addFormVisiblec: false, //编辑界面是否显示
            addFormRules: {
                Name: [{ required: true, message: "请输入菜单名称", trigger: "blur" }],
                Code: [{ required: true, message: "请输入路由地址", trigger: "blur" }],
                // Func: [{ required: true, message: "请输入按钮事件", trigger: "blur" }],
            },
            statusList: [
                { Name: "启用", value: true },
                { Name: "禁用", value: false }
            ],
            //新增/编辑界面数据
            isResouceShow: 1,
            cascTips: "请选择",
            selectVal: '',
            addForm: {
                CreateBy: "",
                CreateId: "",
                dataId: "",
                PidArr: [], //父级菜单
                apiMid: [],
                OrderSort: 1,
                Name: "",
                Code: "",
                Description: "",
                Icon: "",
                Func: "",
                Enabled: true,
                IsButton: false,
                IsHide: false,
            }
        }
    },
    methods: {
        // 筛选框清空，重新获取数据
        clearContent(){
            this.getNavTree()
        },
        // 点击了查询，新增，编辑，删除
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 刷新
        doRefresh() {
            this.getNavTree();
        },
        load(tree, treeNode, resolve) {
            let params = {
                parentId: tree.Id,
                isEnable: tree.IsEnable
            };
            getMenuPermissionsTree(params).then(res => {
                resolve(res.data.Response);
            });
        },
        // 获取菜单树
        getNavTree(params) {
            this.listLoading = true
            getMenuPermissionsTree(params).then(res => {
                let data = res.data
                if(data.Success){
                    this.listLoading = false
                    let result = data.Response
                    this.treeData = result
	                console.log(result,'result')
                }
            })
        },
        // 获取父级菜单
        getParNavTree(propsVal) {
            let params = {
                userID: JSON.parse(localStorage.getItem("user")).Id
            }
            getMenuTree(params).then(res => {
                let data = res.data
                if(data.Success){
                    // this.options = res.Response.children
                    let optionArr = []
                    optionArr.push(data.Response)
                    this.options = optionArr
                    this.addForm.PidArr = propsVal
                }
            })
        },
        selectCurrentRow(val) {
            this.selectVal = val
	        console.log(val,'val')
        },
        selsChange: function(val) {
	        console.log(val,'val222')
            if (val.length > 1) {
                this.$refs.multipleTable.clearSelection(); //清空列表的选中
                this.$refs.multipleTable.toggleRowSelection(val[val.length-1]); //只显示选中最后一个 这时val还是多选的列表(就是你选中的几个数据)
            } else if (val.length === 1) {
                this.multipleTable = val[val.length-1];
                this.selectVal = val[0]
            } else {
                this.multipleTable = []; //this.multipleTable是选中行的最后一条数据
                this.selectVal = ''
            }
        },
        // 查询
        getMenuPermissionsTree(){
            let params = {
                menuPermissionsName : this.filterText,
            }
            this.getNavTree(params);
        },
        // 新增
        handleAdd() {
            this.isAdd = true
            this.addFormVisible = true
            
            a = 1
            this.cascTips = '请选择'
            this.addForm.CreateBy = ''
            this.addForm.CreateId = ''
            this.addForm.dataId = ''
            // this.addForm.PidArr = []
            // this.addForm.apiMid = []
            this.addForm.OrderSort = 1
            this.addForm.Name = ''
            this.addForm.Code = ''
            this.addForm.Description = ''
            this.addForm.Icon = ''
            this.addForm.Func = ''
            this.addForm.Enabled = true
            this.addForm.IsButton = false
            this.addForm.IsHide = false
            // this.addForm.IskeepAlive = false
            this.getParNavTree();
        },
        // 编辑
        handleEdit() {
            if(this.selectVal == ''){
                this.$message({
                    message: '请选择需要编辑的菜单一项',
                    type: 'warning'
                });
                return false;
            }
            let val = this.selectVal
            this.isAdd = false
            this.addFormVisiblec = true

            this.addForm.CreateBy = ''
            this.addForm.CreateId = ''
            this.addForm.dataId = val.Id
            // this.addForm.PidArr = val.ParentIdList
            if(val.ApiModulesInfoID == 0){
                this.addForm.apiMid = ["00"]
            }else{
                this.addForm.apiMid = []
                this.cascTips = val.ApiModulesInfoName
            }
            this.addForm.OrderSort = val.SortNo //排序
            this.addForm.Name = val.Name //菜单名称
            this.addForm.Code = val.RoutingPath //路由地址
            this.addForm.Description = val.Description //描述
            this.addForm.Icon = val.Icon
            if(val.FuncEvent){
                this.addForm.Func = val.FuncEvent  //按钮事件
            }else{
                this.addForm.Func = '-'
            }
            this.addForm.Enabled = val.IsEnable    //状态启用/禁用
            this.addForm.IsButton = val.IsButton  //是否按钮
            this.addForm.IsHide = val.IsHide  //是否隐藏

            let propsVal = val.ParentIdList
            this.getParNavTree(propsVal);
        },
        handleDel() {
            if(this.selectVal == ''){
                this.$message({
                    message: '请选择需要删除的菜单一项',
                    type: 'warning'
                });
                return false;
            }
            let val = this.selectVal
            let params = {
                ID: val.ID
            }
            this.$confirm('确认删除该数据吗?', '提示', {
                type: 'warning'
            }).then(() => {
                deleteMenuPermissionsInfo(params).then(res => {
                    let data = res.data
                    if(data.Success){
                        this.$message({
                            message: "删除成功！",
                            type: "success"
                        });
                        this.doRefresh();
                    }else{
                        this.$message.error(data.Message);
                    }
                })
                .catch(err => {
                    this.$message({
                        message: "删除失败！",
                        type: "error"
                    });
                })
            })
            .catch(() => {
            })
        },
        // 回车按钮
        enterKey(item) {
            this.addSubmit(item);
        },
        // 新增/编辑提交按钮
        addSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let val = this.addForm
                    if(this.isAdd){
                        let params = {
                            RoutingPath: val.Code,
                            Name: val.Name,
                            IsButton: val.IsButton,
                            IsHide: val.IsHide,
                            FuncEvent: val.Func,
                            SortNo: val.OrderSort,
                            Icon: val.Icon,
                            Description: val.Description,
                            IsEnable: val.Enabled,
                        }
                        if(val.PidArr){
                            if(val.PidArr.length > 0){
                                // params.parentId = val.PidArr.pop()
                                params.ParentId = val.PidArr[val.PidArr.length-1]
                            }
                        }
                        if(val.apiMid){
                            if(val.apiMid.length > 0){
                                // if(val.apiMid.pop() == "00"){
                                if(val.apiMid[val.apiMid.length-1] == "00"){
                                    params.ApiModulesInfoID = 0
                                }else{
                                    // params.apiModulesInfoID = val.apiMid.pop()
                                    params.ApiModulesInfoID = val.apiMid[val.apiMid.length-1]
                                }
                            }
                        }
                        if(JSON.parse(localStorage.getItem("user")).Id > 0){
                            params.CreateUserId = JSON.parse(localStorage.getItem("user")).Id
                        }else{
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        this.addLoading = true
                        addMenuPermissionsInfo(params).then(res => {
                            let data = res.data
                            if(data.Success){
                                this.$message({
                                    message: "新增成功！",
                                    type: "success"
                                });
                                this.addFormVisible = false;
                                this.addLoading = false;
                                this.doRefresh();
                            }else{
                                this.$message.error(data.Message);
                                this.addLoading = false;
                            }
                        })
                        .catch(err => {
                            this.$message({
                                message: "新增失败！",
                                type: "error"
                            });
                        })
                    }else{
                        let params = {
                            RoutingPath: val.Code,
                            Name: val.Name,
                            IsButton: val.IsButton,
                            IsHide: val.IsHide,
                            FuncEvent: val.Func,
                            SortNo: val.OrderSort,
                            Icon: val.Icon,
                            Description: val.Description,
                            IsEnable: val.Enabled,
                            Id: val.dataId,
                        }
                        if(val.PidArr.length > 0){
                            // params.parentId = val.PidArr.pop()
                            params.ParentId = val.PidArr[val.PidArr.length-1]
                        }
                        if(val.apiMid.length > 0){
                            // if(val.apiMid.pop() == "00"){
                            if(val.apiMid[val.apiMid.length-1] == "00"){
                                params.ApiModulesInfoID = 0
                            }else{
                                // params.apiModulesInfoID = val.apiMid.pop()
                                params.ApiModulesInfoID = val.apiMid[val.apiMid.length-1]
                            }
                        }
                        if(JSON.parse(localStorage.getItem("user")).Id > 0){
                            params.CreateUserId = JSON.parse(localStorage.getItem("user")).Id
                        }else{
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        this.addLoading = true
                        updateMenuPermissionsInfo(params).then(res => {
                            let data = res.data
                            if(data.Success){
                                this.$message({
                                    message: "更新成功！",
                                    type: "success"
                                });
                                this.addFormVisiblec = false;
                                this.addLoading = false;
                                this.doRefresh();
                            }else{
                                this.$message.error(data.Message);
                                this.addLoading = false;
                            }
                        })
                        .catch(err => {
                            this.$message({
                                message: "编辑失败！",
                                type: "error"
                            });
                        })
                    }
                }
            })
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        },
    },
    watch: {
        options() {
            this.isResouceShow++
        }
    },
    created() {
			// this.getMenuPermissionsTree()
        this.doRefresh();
    },
    mounted() {
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
.topTools{
    position fixed;
    top 110px;
    z-index 50;
    background-color #fff;
}
.bodyTools{
    margin-top 65px;
}
</style>